const checkIpAddr = async (context) => {
  return await context.app.$axios
    .get("https://api.ipdata.co/?api-key=" + context.$config.geoLocationIp)
    .then((data) => {
      return data;
    })
    .catch((e) => {
      console.log(e);
    });
};

const checkAvailStores = async (context) => {
  const { data } = await context.app.$vsf.$magento.api.availableStores({ availableStores: "availableStores" }, {});
  return data;
};

const changeStore = (context, store_code, availStores) => {
  const newStoreUrl = "/" + store_code;
  window.open(newStoreUrl, "_self");
};

const plugin = async (context) => {
 

};
export default plugin;
